// ServiceIntroPage.js
import React from 'react';
import './ServiceIntroPage.css';

const ServiceIntroPage = () => {
  return (
    <div className="service-intro-container">
      <h1 className="titleintro">서비스 소개</h1>
      <h2 className="subtitleintro">물가 안정을 위한 작은 희망,<br/>여러분과 함께 만들어가는 우리의 서비스입니다.</h2>
      <div className="image-container"></div>
      <p className="contentintro">
         최근 외식 물가의 상승, 여러분도 느끼셨나요?<br/><br/>맛있는 한 끼를 위해 식당 문을 열 때마다 부담스러운 가격표와 마주하는 일,<br/><br/>
        저희는 이러한 상황을 개선하기 위해, 외식 물가 안정을 목표로 하는 서비스를 시작했습니다. '가치 있는 식사, 부담 없는 가격'이라는 모토 아래<br/><br/>
        물가의 불안정성 속에서도 소비자의 부담을 덜어주고, 일상 속 작은 안정을 찾아드리고자 이 서비스를 운영하고 있습니다.<br/>물가 안정은 단순히 숫자의 문제가 아닙니다. 그것은 우리 모두의 삶에 직접적으로 영향을 미치는 중요한 요소입니다.<br/><br/>
        이를 안정시키기 위해 전국에 퍼져있는 착한 가격의 음식점 정보를 모으고 지도에 표시하여, 이용자들이 가까운 음식점을 쉽게 찾을 수 있게 하고 있습니다.<br/><br/>
        저희 서비스, "절약의 맛"을 통해 여러분이 느끼실 수 있는 것은 단순한 가격적인 이익을 넘어, 삶의 질을 향상시키는 경험이 될 것입니다.<br/><br/>물가 안정은 우리 모두의 행복한 미래를 위한 발걸음이며, 여러분과 함께라면 그 길을 힘차게 걸어갈 수 있을 것이라 믿습니다.<br/><br/><br/>
        감사합니다.
      </p>
    </div>
  );
}

export default ServiceIntroPage;
