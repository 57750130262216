import React, { useState , useEffect } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import TypeIt from "typeit-react";
import globalState from './globalState'; // 전역변수
import Swal from 'sweetalert2';


const Header = () => {
  const navigate = useNavigate();

  const [installPrompt, setInstallPrompt] = useState(null);

 // 메뉴 표시 상태를 관리하는 state
 const [isMenuVisible, setIsMenuVisible] = useState(false);
 const [isClicked, setIsClicked] = useState(false);
 const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

 const [isNav1ButtonClicked, setIsNav1ButtonClicked] = useState(false);
 const [isNav2ButtonClicked, setIsNav2ButtonClicked] = useState(false);
  const [isNav3ButtonClicked, setIsNav3ButtonClicked] = useState(false);
 const [isNav4ButtonClicked, setIsNav4ButtonClicked] = useState(false);

 useEffect(() => {
  const beforeInstallPromptHandler = (e) => {
    e.preventDefault();
    setInstallPrompt(e);
  };

  window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

  return () => {
    window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  };
}, []);

 const handleClick = () => {
  //디테일 페이지 방문 여부를 초기화
//  globalState.detailVisited = false;
  //navigate('/');
  window.open("/", "_blank");
};
 // 메뉴 표시 상태를 토글하는 함수
 const toggleMenu = () => {
   setIsMenuVisible(!isMenuVisible);

   setIsClicked(true);
   setTimeout(() => setIsClicked(false), 400); // 애니메이션 시간에 맞춰 상태 변경

 };

 const handleClickAddButton = () => {
    setIsAddButtonClicked(true);
    setTimeout(() => setIsAddButtonClicked(false), 400);
    navigate('/submission'); // 이 줄을 추가합니다.
  }

  const nav1HandleClickButton = () => {
    setIsNav1ButtonClicked(true);
     //디테일 페이지 방문 여부를 초기화
  globalState.detailVisited = false;
    setTimeout(() => setIsNav1ButtonClicked(false), 400);
    navigate('/'); // 홈 페이지로 이동
  }
  const nav2HandleClickButton = () => {
    setIsNav2ButtonClicked(true);
    setTimeout(() => setIsNav2ButtonClicked(false), 400);
    navigate('/serviceintro'); // 이 줄을 추가합니다.
  }
  const nav3HandleClickButton = () => {
    setIsNav3ButtonClicked(true);
    setTimeout(() => setIsNav3ButtonClicked(false), 400);
////
  // 아이폰 사용자 감지
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // 아이폰 사용자에게 안내 메시지 표시
    Swal.fire({
      icon: "info",
      title: "홈 화면에 추가하기",
      html: "공유 버튼을 통해 <b>홈 화면에 추가하기</b>를 진행해 주세요."
    });
    return; // 아이폰 사용자의 경우 여기서 함수 종료
  }


if (installPrompt) {
  installPrompt.prompt();
  installPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
     // console.log('User accepted the install prompt');
     Swal.fire("바로가기 추가 중..", "추가가 완료되면<br>앱 목록에서 절약의 맛을 찾을 수 있습니다.", "성공");
    } else {
      console.log('User dismissed the install prompt');
    }
    setInstallPrompt(null);
  });
} else {
 // alert('현재 폰에서는 바로가기 앱을 설치할 수 없습니다.');
 Swal.fire({
  icon: "warning",
  title: "죄송합니다.",
  text: "사용 중인 환경에서 바로가기가 지원되지 않습니다."
});
}
/////


  }
  const nav4HandleClickButton = () => {
    setIsNav4ButtonClicked(true);
    setTimeout(() => setIsNav4ButtonClicked(false), 400);
    navigate('/submission'); // 제보하기 페이지로 이동

  }

  return (
    <div className="header">
              <div className="header-content">
              <div className={`menu-button ${isClicked ? 'menu-button-clicked' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div className={`add-button ${isAddButtonClicked ? 'menu-button-clicked' : ''}`} onClick={handleClickAddButton}>
        <strong>+</strong>
      </div>
              <TypeIt  options={{
    
    speed: 130,
    startDelay: 400,
    cursor: false
  }} className="title" onClick={handleClick}>절약의 <span className="orange-text">맛</span></TypeIt>
              {/* <TypeIt>This will be typed in a `span` element!</TypeIt> */}
              
     
     
      </div>
     
      {isMenuVisible && (
       <nav className={`nav-menu ${isMenuVisible ? 'visible' : ''}`}>
          {/* 네비게이션 메뉴 항목들 */}
     
          <ul>
            <li className={`nax1 ${isNav1ButtonClicked ? 'menu-button-clicked' : ''}`} onClick={nav1HandleClickButton}><a href="#home" >홈</a></li>
            <li className={`nax2 ${isNav2ButtonClicked ? 'menu-button-clicked' : ''}`} onClick={nav2HandleClickButton}><a href="#services" >서비스 소개</a></li>
            <li className={`nax3 ${isNav3ButtonClicked ? 'menu-button-clicked' : ''}`} onClick={nav3HandleClickButton}><a href="#about" >바로가기 추가</a></li>
            <li className={`nax4 ${isNav4ButtonClicked ? 'menu-button-clicked' : ''}`} onClick={nav4HandleClickButton}><a href="#contact" >가격 제보</a></li>
          </ul>
        
        </nav>
      )}
            <div className="divider"></div>
          
    </div>
  );
};

export default Header;
