// globalState.js
let globalState = {

    detailVisited: false,
    dataFinished: false,
    fetchedIds: [],
    latitude: null,
    longitude: null
  };
  
  export default globalState;
  