// RestaurantsList.js
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import RestaurantCard from './RestaurantCard';
import './RestaurantsList.css';
import Loading from './Loading';
import globalState from './globalState'; // 전역변수
import { RestaurantsContext } from './RestaurantsContext';
import Swal from "sweetalert2";
import WelcomeModal from './WelcomeModal'; // WelcomeModal 컴포넌트를 임포트합니다.


function RestaurantsList() {
  // const [restaurants, setRestaurants] = useState([]);
  const { restaurants, setRestaurants } = useContext(RestaurantsContext);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchMade, setSearchMade] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [searchTerm2, setSearchTerm2] = useState('');
  // const limit = 6; // 페이지당 항목 수

  // const fetchRestaurants = useCallback(async () => {
  //   if (loading) return;
  //   setLoading(true);
  //   setSearchCompleted(false);

  //   try {
  //     const response = await fetch(`http://localhost:4000/api/restaurants?page=${page}&search=${searchTerm}`);
  //     if (!response.ok) throw new Error('Network response was not ok');
  //     const data = await response.json();
  //     setRestaurants(prev => [...prev, ...data]);
  //   } catch (error) {
  //     console.error('Fetch error:', error);
  //   } finally {
  //     setLoading(false);
  //     setSearchCompleted(true);
  //   }
  // }, [loading, page, searchTerm]); 


  const fetchedIdsRef = useRef([]);

  //////////
  const latitude = useRef('');
  const longitude = useRef('');

  const getlocation = () => {
    // 검색 순간 다시 시작해야하니깐 fetchedIdsRef를 빈 배열로 초기화,방문여부를 초기화
    fetchedIdsRef.current = [];
    globalState.fetchedIds = [];
    globalState.detailVisited = false;
    globalState.dataFinished = false;
    setPage(prev => prev + 1);
    // setPage(0);
    setSearchTerm(''); // searchTerm을 업데이트하여 검색 실행
    setRestaurants([]); //레스토랑도 초기화
    setSearchMade(true);  // Set searchMade to true when a search is submitted
      // Scroll the window down to 30% of the total height
      const scrollHeight = document.documentElement.scrollHeight;
      window.scrollTo(0, scrollHeight * 0.15);

  };

  const getLalo = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          latitude.current = position.coords.latitude;
          longitude.current = position.coords.longitude;
          globalState.latitude = position.coords.latitude;
          globalState.longitude = position.coords.longitude;

          console.log(`Latitude: ${latitude.current}, Longitude: ${longitude.current}`);
          getlocation();
          // 필요한 로직 추가 (예: 레스토랑 데이터 가져오기 등)
        },
        (error) => {
          Swal.fire("GPS 위치 권한 필요", "웹 브라우저 주소 입력창 왼쪽의 설정,자물쇠 버튼", "OK");
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    } else {

      Swal.fire("GPS 권한 필요", "GPS가 지원되지 않는 환경입니다.", "OK");

      // alert("Geolocation is not supported by this browser.");
    }
  };
  ///////////

  const fetchRestaurants = useCallback(async () => {

    console.log(`Fetching restaurants... Page: ${page}, Fetched IDs: ${fetchedIdsRef.current}`);
    console.log(`Fetching 전역... Page: ${page}, Fetched IDs: ${globalState.fetchedIds}`);
    setLoading(true);
    setSearchCompleted(false);
    console.log(`Latitude트라이직전: ${latitude.current}, Longitude: ${longitude.current}`);
    try {
      const excludeIds2 = fetchedIdsRef.current.join(',');
      const excludeIds = globalState.fetchedIds.join(',');
      console.log(`Fetching restaurants... Page: ${page}, excludeIds IDs: ${excludeIds2}`);
      console.log(`Fetching 전역.. Page: ${page}, excludeIds IDs: ${excludeIds}`);
      // 요청 URL에 이미 불러온 ID 목록 추가
      //  const response = await fetch(`https://foodzul.com/getre?page=${page}&search=${searchTerm}&excludeIds=${excludeIds}`);

      const response = await fetch(`https://foodzul.com/getre?page=${page}&search=${searchTerm}&excludeIds=${excludeIds}&latitude=${latitude.current}&longitude=${longitude.current}`);

      // const response = await fetch(`https://foodzul.com/getre?page=${page}&search=${searchTerm}`);
      if (!response.ok) throw new Error('Network response was not ok');



      // 'results' 필드에서 실제 데이터 배열 추출
      const jsonResponse = await response.json();
      const data = jsonResponse.results;
      console.log(`Fetching restaurants... 결과값: ${data}`);

      if (data.length === 0) {
        globalState.dataFinished = true; // 데이터가 더 이상 없음을 나타냄
      }

      if (!Array.isArray(data)) {
        throw new Error('Received data is not an array');
      }

      setRestaurants(prev => [...prev, ...data]);
      // 불러온 데이터의 ID를 fetchedIdsRef에 추가
      const idsFromNewData = data.map(item => item.id);
      fetchedIdsRef.current = [...new Set([...fetchedIdsRef.current, ...idsFromNewData])]; // 중복 제거를 위해 Set 사용
      // 예를 들어 데이터를 성공적으로 가져온 후:
      globalState.fetchedIds = [...new Set([...globalState.fetchedIds, ...idsFromNewData])];


    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
      setSearchCompleted(true);
    }
  }, [searchTerm, page, setRestaurants]);



  useEffect(() => {

    if (!globalState.detailVisited) {

      if (!globalState.dataFinished) {
        fetchRestaurants(); // 데이터 패치 함수
      }
    }

  }, [fetchRestaurants]);



  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (!loading && scrollHeight - scrollTop - 10 <= clientHeight) {
      console.log(`handleScroll triggered: Current page ${page}, Setting page to ${page + 1}`);
      globalState.detailVisited = false;
      setPage(prev => prev + 1);
    }
  };

  // const handleScroll = (e) => {
  //   const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
  //   if (!loading && scrollHeight - scrollTop <= clientHeight * 2 && restaurants.length === (page - 1) * limit) {
  //     setPage(prev => prev + 1);
  //   }
  // };



  const handleSearchChange = (e) => {
    console.log(`Search term changed: ${e.target.value}`);
    //  setSearchTerm(e.target.value);
    setSearchTerm2(e.target.value);
  };

  const handleSearchSubmit = () => {

    // searchTerm2와 현재 searchTerm이 다른 경우에만 로직 실행
    if (searchTerm2 !== searchTerm) {
      // 검색 순간 다시 시작해야하니깐 fetchedIdsRef를 빈 배열로 초기화,방문여부를 초기화
      fetchedIdsRef.current = [];
      globalState.fetchedIds = [];
      globalState.detailVisited = false;
      globalState.dataFinished = false;

      setPage(1);
      setSearchTerm(searchTerm2); // searchTerm을 업데이트하여 검색 실행
      setRestaurants([]); //레스토랑도 초기화
      setSearchMade(true);  // Set searchMade to true when a search is submitted
      //  fetchRestaurants();
    }
    //그렇지 않다면 아무것도 하지 않음 (검색어가 같은 경우)
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // console.log(`Search term changed: ${e.target.value}`);
      // setSearchTerm(e.target.value);
      handleSearchSubmit();
      // 모바일 키보드를 내리기 위해 포커스 제거
      e.target.blur();
    }
  };


  return (
    <div onScroll={handleScroll} style={{ overflowY: 'auto', height: '100vh' }}>
      
      <h3 className="searchtitle">오늘 한끼, 물가를 지키는</h3>
      <h3 className="searchtitle2">착한 가게에 방문해 볼까요?</h3>

      <div className="search-container">
        <input
          className="search-input"
          type="text"
          value={searchTerm2}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder="지역,음식,가게이름 등 검색"
        />
        <button className="search-button" onClick={handleSearchSubmit}></button>
      </div>
      <button className="sub-button" onClick={getLalo}>가까운 <span className="orange-text2">맛</span></button>
      {searchCompleted && restaurants.length === 0 && searchMade && <p className="no-results">검색 결과가 없습니다.</p>}
      {searchCompleted && restaurants.length === 0 && searchMade && <p className="no-results">착한 가게를 찾으시면 언제든 제보해주세요.😍</p>}
      {restaurants.map(restaurant => (
        <RestaurantCard key={restaurant.id} restaurant={restaurant} />
      ))}
      {loading && <Loading />}
      <WelcomeModal />
    </div>
  );
}

export default RestaurantsList;
