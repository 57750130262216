// Footer.js
import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const clickButton = () => {
     // 페이지 상단으로 스크롤 이동
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 부드러운 스크롤 효과
  });
    navigate('/submission'); // 제보하기 페이지로 이동

  }


  return (
    <footer className="footer-container">
      <div className="footer-content">
      <div className="footer-contentin">
      <div className="footer-title">
          <h2 className="title2" >절약의 <span className="orange-text">맛</span></h2>
        </div>
        <div className="footer-button">
          <button className="submit-buttonf" onClick={clickButton}>착한 가격 제보</button>
        </div>
</div>
      <p className="contentfoot">물가 안정을 위한 여러분의 참여에 진심으로 감사드립니다.</p>
        <p className="contentfoot">문의: votecharm@gmail.com</p>
        <p className="contentfoot">Copyright © 절약의 맛</p>
      </div>
    </footer>
  );
};

export default Footer;
