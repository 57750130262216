import React from "react";
import { RingLoader } from "react-spinners";
import './Loading.css'; // CSS 파일 임포트

function Loading() {
  return (
    <div className="contentWrap">
      <RingLoader
        color="#36d6b6"
        size={70} // 원하는 크기로 조절
      />
    </div>
  );
}

export default Loading;
