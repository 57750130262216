import React, {useState, useRef } from 'react';
import './SubmissionPage.css';
import Loading from './Loading';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function SubmissionPage() {
  const storeNameRef = useRef(null);
  const menuPriceRef = useRef(null);
  const locationRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async () => {
      // 모든 필드가 채워져 있는지 확인
        // 입력값 검사
  const storeName = storeNameRef.current.value;
  const menuPrice = menuPriceRef.current.value;
  const location = locationRef.current.value;

  if (!storeName || !menuPrice || !location) {
    // 필드가 하나라도 비어있으면 경고 메시지 표시
    await Swal.fire({
      icon: "warning",
      title: "앗! 빈칸이 있어요",
      text: "빈칸을 작성해서 제출해주세요."
    });
    return; // 함수를 여기서 종료
  }

    setLoading(true);
  
  
    // const formData = { storeName, menuPrice, location };
    const formData = {
      storeName: storeNameRef.current.value,
      menuPrice: menuPriceRef.current.value,
      location: locationRef.current.value,
      timestamp: new Date().toISOString() // 현재 시간 추가
    };
  
    try {
      // const response = await fetch('http://localhost:4000/submit', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(formData),
      // });
      const response = await fetch('https://foodzul.com/getsub', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        // Handle success - maybe clear the form or display a success message
        setLoading(false);
       // 성공 메시지 표시
       await Swal.fire("제보 완료!", "감사합니다.<br>당신의 제보가 물가를 지키는 힘이 됩니다.", "성공");
       navigate('/'); // Navigate to home page
      } else {
        setLoading(false);
        const errorResult = await response.json(); // 오류 메시지도 JSON 형태로 가정
    console.error(errorResult.message);
        Swal.fire({
          icon: "error",
          title: "서버 문제",
          text: "문제를 해결하기 위해 노력중입니다.",
  
        });
        throw new Error('Submission failed');
       
      }
    } catch (error) {
      console.error(error);
      // Handle error - maybe display an error message
      setLoading(false);
      // 오류 메시지 표시
      Swal.fire({
        icon: "error",
        title: "서버 문제",
        text: "문제를 해결하기 위해 노력중입니다.",

      });
    }
  };
  

  return (
    <div className="submission-container">
      <h1 className="main-title">착한 가격 제보</h1>
      <h2 className="sub-title">물가 안정의 첫 걸음, 우리 동네 가격 착한 가게를 공유해주세요.</h2>

      <h5 className="subtitle">가게 이름</h5>
      <input 
        type="text" 
        ref={storeNameRef}
       
        className="input-field" 
      />

      <h5 className="subtitle">음식 이름, 가격</h5>
      <input 
        type="text" 
        ref={menuPriceRef}
       
        className="input-field2" 
      />

      <h5 className="subtitle">위치</h5>
      <input 
        type="text" 
        ref={locationRef}
        
        className="input-field3" 
      />
      <button className="submit-button" onClick={handleSubmit}>작성 완료</button>
      {loading && <Loading />}
    </div>
  );
}

export default SubmissionPage;
