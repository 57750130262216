import React, { useEffect } from 'react';
import Swal from 'sweetalert2';


function WelcomeModal() {
  useEffect(() => {
    const isFirstVisit = localStorage.getItem('firstVisit') === null;
    if (isFirstVisit) {
      localStorage.setItem('firstVisit', 'no');
      Swal.fire({
        title: '<strong>환영합니다!</strong>',
        html: '검색창 아래 (가까운 <span class="orange-text2">맛</span>) 버튼으로<br>내 위치와 가까운 가게를 찾아보세요.',
        icon: 'info',
        confirmButtonText: '닫기'
      });
    }
  }, []);

  return <></>;
}

export default WelcomeModal;
