// RestaurantCard.js
//import React from 'react';
import React, { useState, useEffect } from 'react';

import './RestaurantCard.css'; // 여기에 스타일을 정의합니다.
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import beer20 from '../images/beer20.webp';
import bab0 from '../images/bab0.webp';
import bever7 from '../images/bever7.webp';
import bread6 from '../images/bread6.webp';
import bung28 from '../images/bung28.webp';
import chicken9 from '../images/chicken9.webp';
import coffe21 from '../images/coffe21.webp';
import dduck25 from '../images/dduck25.webp';
import dongas12 from '../images/dongas12.webp';
import dubbab27 from '../images/dubbab27.webp';
import fish4 from '../images/fish4.webp';
import gugbab13 from '../images/gugbab13.webp';
import hamber11 from '../images/hamber11.webp';
import mandu24 from '../images/mandu24.webp';
import meaticon1 from '../images/meaticon1.webp';
import noodle2 from '../images/noodle2.webp';
import pigleg3 from '../images/pigleg3.webp';
import pizza10 from '../images/pizza10.webp';
import soju8 from '../images/soju8.webp';
import sushi5 from '../images/sushi5.webp';
import tang29 from '../images/tang29.webp';
import tangsu30 from '../images/tangsu30.webp';
import defaultImage from '../images/searchicon.webp';
import wine31 from '../images/wine31.webp';
import liquir32 from '../images/liquir32.webp';
import gimbab26 from '../images/gimbab26.webp';

import jja34 from '../images/jja34.webp';
import jjam35 from '../images/jjam35.webp';
import ramen33 from '../images/ramen33.webp';
import gae36 from '../images/gae36.webp';
import globalState from './globalState'; // 전역변수




 

const RestaurantCard = ({ restaurant }) => {

  let navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    navigate(`/restaurants/${restaurant.id}`, { state: { restaurant: restaurant } });

  };

  useEffect(() => {
    let timer = setInterval(() => {
        if (count < restaurant.price) {
            setCount(count + 100);
            setAnimate(false);
        } else {
            clearInterval(timer);
            setAnimate(true); // 최종 값에 도달했을 때만 애니메이션 효과를 활성화
        }
    }, 20);

    return () => clearInterval(timer);
}, [count, restaurant.price]);

const priceClass = `restaurant-price ${animate ? 'puff-effect' : 'fade-in'}`;



  const getImageBasedOnCondition = (condition) => {
    switch (condition) {
      case '한식':
        return bab0;
      case '맥주':
        return beer20;
      case '음료':
        return bever7;
      case '빵':
        return bread6;
      case '붕어빵':
        return bung28;
      case '치킨':
        return chicken9;
      case '커피':
        return coffe21;
      case '떡볶이':
        return dduck25;
      case '돈가스':
        return dongas12;
      case '덮밥':
        return dubbab27;
      case '생선':
        return fish4;
      case '국밥':
        return gugbab13;
        case '김밥':
        return gimbab26;
      case '햄버거':
        return hamber11;
      case '만두':
        return mandu24;
      case '고기':
        return meaticon1;
      case '면':
        return noodle2;
      case '족발':
        return pigleg3;
      case '피자':
        return pizza10;
      case '소주':
        return soju8;
      case '초밥':
        return sushi5;
      case '탕후루':
        return tang29;
      case '탕수육':
        return tangsu30;
        case '와인':
        return wine31;
        case '양주':
        return liquir32;
        case '라면':
        return ramen33;
        case '짜장면':
        return jja34;
        case '짬뽕':
        return jjam35;
        case '꽃게':
          return gae36;

      default:
        return defaultImage; // Default image for conditions not listed
    }
  };
  
// Haversine formula를 사용하여 거리 계산
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if (lat1 && lon1 && lat2 && lon2) {
    const R = 6371; // 지구의 반지름(km)
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance.toFixed(2); // 소수점 둘째자리까지 반환
  }
  return null;
};

// 사용자의 현재 위치와 레스토랑 위치 간의 거리 계산
const distance = calculateDistance(
  globalState.latitude, 
  globalState.longitude, 
  restaurant.temp5, 
  restaurant.temp6
);

  return (
    <div className="restaurant-card" onClick={handleClick}>
       <LazyLoadImage
        alt={restaurant.storename}
        height="70px" // 원하는 이미지 높이 설정
        src={getImageBasedOnCondition(restaurant.temp4)}// 실제 이미지 URL
        width="70px" // 원하는 이미지 너비 설정
        effect="blur" // 로딩 시 보여줄 효과
        style={{ borderRadius: '10px' }} 
      />
   
      <div className="restaurant-info">
        <h2 className="restaurant-food">{restaurant.food}</h2>
        {/* <p className="restaurant-price">{restaurant.price}원</p> */}
        {/* <p className="restaurant-price fade-in">{count}원</p> */}

        <p className={priceClass}>{count}원</p>
      </div>

      <div className="restaurant-loinfo">
        <h2 className="restaurant-location">📌 {restaurant.location}</h2>
        {distance && (
        <div className="restaurant-distance">
          <p>{distance} km</p>
        </div>
      )}
      </div>
    </div>
  );
};

export default RestaurantCard;
