import './App.css';
import Header from './compo/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import RestaurantsList from './compo/RestaurantsList'; // RestaurantsList 컴포넌트를 임포트
import RestaurantDetails from './compo/RestaurantDetails'; // 상세 페이지 컴포넌트 임포트
import SubmissionPage from './compo/SubmissionPage'; // SubmissionPage 컴포넌트 임포트
import Footer from './compo/Footer'; // Footer 컴포넌트 임포트
import ServiceIntroPage from './compo/ServiceIntroPage'; // SubmissionPage 컴포넌트 임포트

import { RestaurantsProvider } from './compo/RestaurantsContext'; // RestaurantsContext 임포트


function App() {
  return (
    <Router>
      <RestaurantsProvider>
        <div className="App">
          <Header />


          <Routes>
            <Route path="/" element={<RestaurantsList />} />
            <Route path="/restaurants/:id" element={<RestaurantDetails />} />
            <Route path="/submission" element={<SubmissionPage />} /> {/* 이 줄 추가 */}
            <Route path="/serviceintro" element={<ServiceIntroPage />} />

          </Routes>
          <Footer />
        </div>
      </RestaurantsProvider>
    </Router>
  );
}

export default App;
