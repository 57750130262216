//import React from 'react';
//import { useLocation } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './RestaurantDetails.css'; // 여기에 스타일을 정의합니다.
//import { useEffect } from 'react';
import { LineShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import {   LineIcon, XIcon, FacebookIcon } from "react-share";
import Loading from './Loading';
import NaverBlog from './naverblog';
import globalState from './globalState'; // 전역변수




const RestaurantDetails = () => {
  const [restaurant, setRestaurant] = useState(null);
  const location = useLocation();
  const { id } = useParams();
  const shareUrl = window.location.href;  // Gets the current page's URL
  const [loading, setLoading] = useState(false);
  const [showBlog, setShowBlog] = useState(true);
  
  /////
  const [animate, setAnimate] = useState(false);
////////////



  //////////

  const handleScroll = () => {
    const scrollY = window.scrollY; // Current scroll position
    const screenHeight = window.innerHeight; // Height of the screen
  
    // Trigger animation when scrolled more than half of the screen height
    if (scrollY > screenHeight*1 / 3) {
      setAnimate(true);
    //  setShowBlog(true); 
    } else {
      setAnimate(false);
    }
  };

  useEffect(() => {
    globalState.detailVisited = true;
    setShowBlog(true); 
    return () => {
     // globalState.detailVisited = false; // 컴포넌트 언마운트 시 값 초기화
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  
    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
    
  

 useEffect(() => {
  // Kakao SDK 스크립트 로드
  const script = document.createElement("script");
  script.src = "https://developers.kakao.com/sdk/js/kakao.js";
  document.head.appendChild(script);

  script.onload = () => {
    // 스크립트 로드가 완료되면 초기화
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init("04b04c8905f7f7d4fca48995ee3dd85d");
   //   window.Kakao.init(process.env.REACT_APP_MAP);
    }
  };

  // 컴포넌트 언마운트 시 스크립트 제거
  return () => {
    document.head.removeChild(script);
  };
}, []);


 const shareKakao = () => {

  const toast = document.getElementById("toast-message");
  toast.textContent = "카톡 실행중..."; // 텍스트 내용 변경
  toast.classList.add("show");
  setTimeout(() => {
    toast.classList.remove("show");
  }, 3000); // 3초 후 메시지 숨김


  if (window.Kakao) {
    const kakao = window.Kakao;
    
    if (!kakao.isInitialized()) {
      kakao.init('04b04c8905f7f7d4fca48995ee3dd85d');
   // kakao.init(process.env.REACT_APP_MAP);
    }
  
  
    kakao.Share.sendDefault({
      objectType: "feed", 
      content: {
        title: `${restaurant.location}:${restaurant.food}`,
        description: `${restaurant.price}원에 파는 가게`,
        imageUrl:
          "https://unstruth.com/wp-content/uploads/2024/03/icon.webp",
        link: {
        //  mobileWebUrl: window.location.href,
        //  webUrl: window.location.href,
          mobileWebUrl: `https://foodzul.com/restaurants/${id}`,
          webUrl: `https://foodzul.com/restaurants/${id}`
          
        },
      },
      buttons: [
        {
          title: "착한가격 보러 가기",
          link: {
          //  mobileWebUrl: window.location.href,
           // webUrl: window.location.href,
           mobileWebUrl: `https://foodzul.com/restaurants/${id}`,
           webUrl: `https://foodzul.com/restaurants/${id}`
           
          },
        },
      ],
    });
  }
 
};

//////////////
  
  useEffect(() => {
    setLoading(true); // Set loading to true at the beginning
    // location.state에 restaurant 객체가 있는 경우 사용하고, 없으면 서버에서 데이터 가져오기
    if (location.state && location.state.restaurant) {
     
      setRestaurant(location.state.restaurant);
      setLoading(false);
    } else {
      //  fetch(`http://192.168.35.117:4000/api/restaurants/${id}`)
      
      fetch(`https://foodzul.com/getone?id=${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setRestaurant(data.results[0]);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    }
  }, [id, location.state]);

  //const { restaurant } = location.state || {};
   //스크립트 파일 읽어오기
   const new_script = src => { 
    return new Promise((resolve, reject) => { 
      const script = document.createElement('script'); 
      script.src = src; 
      script.addEventListener('load', () => { 
        resolve(); 
      }); 
      script.addEventListener('error', e => { 
        reject(e); 
      }); 
      document.head.appendChild(script); 
    }); 
  };
  const copyToClipboard = () => {
 //   navigator.clipboard.writeText(restaurant.temp1);
 navigator.clipboard.writeText(restaurant.temp1 + ' ' + restaurant.storename);

    const toast = document.getElementById("toast-message");
    toast.textContent = "복사되었습니다!"; // 텍스트 내용 변경
    toast.classList.add("show");
    setTimeout(() => {
      toast.classList.remove("show");
    }, 3000); // 3초 후 메시지 숨김
  };

  const toastUp = (message) => {
    const toast = document.getElementById("toast-message");
    toast.textContent = message; // 인자로 받은 텍스트로 내용 변경
    toast.classList.add("show");
    setTimeout(() => {
      toast.classList.remove("show");
    }, 3000); // 3초 후 메시지 숨김
  };
  
  useEffect(() => { 

    if (restaurant) {
    // 카카오맵 스크립트 읽어오기
    const my_script = new_script('https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=04b04c8905f7f7d4fca48995ee3dd85d');
   // const my_script = new_script(`https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${process.env.REACT_APP_MAP}`);

    // 스크립트 읽기 완료 후 카카오맵 설정
    my_script.then(() => { 
    
      const kakao = window['kakao']; 
      kakao.maps.load(() => {
        const mapContainer = document.getElementById('map');
  
        // 위도와 경도를 restaurant 객체에서 추출
        const latitude = restaurant.temp5;
        const longitude = restaurant.temp6;
  
        const options = { 
          center: new kakao.maps.LatLng(latitude, longitude), // 좌표 설정
          level: 7 
        }; 
        const map = new kakao.maps.Map(mapContainer, options); // 맵 생성
  
        // 마커 설정
        const markerPosition = new kakao.maps.LatLng(latitude, longitude); 
        const marker = new kakao.maps.Marker({ 
          map: map,
          position: markerPosition
        }); 
        // 인포 윈도우 생성 및 열기
        const infowindow = new kakao.maps.InfoWindow({
          content: `<div class="infowindow-style">${restaurant.storename}</div>`
          // 레스토랑 이름
        });
        infowindow.open(map, marker);
        marker.setMap(map); 

        var mapTypeControl = new kakao.maps.MapTypeControl();

// 지도 오른쪽 위에 지도 타입 컨트롤이 표시되도록 지도에 컨트롤을 추가한다.
map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
        // 줌 컨트롤 생성 및 추가
    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
      });   
    }); 

  }
  }, [restaurant]);
   // 예를 들어 검색어로 레스토랑 작성자를 사용
   const searchQuery = restaurant && restaurant.writer;

  let title = '';
  if (restaurant) {
    title = `${restaurant.food} - ${restaurant.price} 원 가게`;
  }
  const goToTmap = () => {
    const tmapUrl = `https://apis.openapi.sk.com/tmap/app/routes?appKey=XBrF5ILGAB4eUH610xEIm5hMX7O7Fyr44qJhDxjm&name=${encodeURIComponent(restaurant.storename)}&lon=${restaurant.temp6}&lat=${restaurant.temp5}`;
    window.location.href = tmapUrl;
  };

  return (
    <div>
      {restaurant && (
        <>
        <h2 className="food"> {restaurant.location} </h2>

        <div className="container">
  <div className="price-area">
    <h5>Menu</h5>
    <p>{restaurant.food}</p>
  </div>
  <div className="location-area">
    <h5>Price</h5>
    <p>{restaurant.price} 원</p>
  </div>
</div>

<div className="price-area">
    <h5>가게 이름</h5>
    <p>{restaurant.storename}</p>
  </div>

  <div className="price-area">
    {/* <h5>특징</h5> */}
    <p className="restaurant-temp">"{restaurant.temp2}"</p>
  </div>

  <div id="map" className="map"/>
  <div id="toast-message" className="toast-message">복사되었습니다!</div>

  <div className="price-area">
    <h5>주소</h5>
    <p className="addre">{restaurant.temp1} <button className="copy-button" onClick={copyToClipboard}>복사</button> <button onClick={goToTmap} className="tmap-button">T맵 찾기</button>
    </p>
    <p className="open-hour">영업시간: {restaurant.temp3}</p>
    

   
  </div>
  


          <h4 className="center-text"> </h4>
          
          <div className={`price-areaver2 ${animate ? "slide-in" : "slide-out"}`}>

          <h5 className="lucky-title">행운의 편지</h5>
           <h4 className="lucky-contents"> 🗨️ 물가 하락의 첫 걸음, </h4>
           <h4 className="lucky-contents2"> 지금 본 착한 가격을 친구 2명에게 알리지 않으면...</h4>

         
           <div className="button-container">
              <FacebookShareButton className='share-button2' url={shareUrl} quote={title} onClick={() => toastUp("페북 실행중...")}>
                   <FacebookIcon size={32} round />
                
              </FacebookShareButton>

              <TwitterShareButton className='share-button2' url={shareUrl} title={title} onClick={() => toastUp("X 실행중...")}>
                    <XIcon size={32} round />
                 
               </TwitterShareButton>
               <LineShareButton className='share-button2' url={shareUrl} quote={title} onClick={() => toastUp("라인 실행중...")}>
                   <LineIcon size={32} round />
                
              </LineShareButton>
              <button className='share-button' onClick={shareKakao}>카톡 공유하기</button>
           </div>
          </div>

      
           {showBlog && <NaverBlog query={searchQuery} />}
         
          <h4 className="store-name"> </h4>
         
        </>
      )}
         {loading && <Loading />}
    </div>
  );
};

export default RestaurantDetails;
