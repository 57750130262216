// naverblog.js
import React, { useState, useEffect } from 'react';
import './naverblog.css'; // 여기에 스타일을 정의합니다.

//
function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

function removeHtmlTagsAndDecode(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // HTML 태그 제거
  str = str.replace(/(<([^>]+)>)/gi, "");
  // HTML 엔티티 디코드
  return decodeHtml(str);
}
//
// function removeHtmlTags(str) {
//     if (str === null || str === "") return false;
//     else str = str.toString();
     
//     return str.replace(/(<([^>]+)>)/gi, "");
//   }

  
const NaverBlog = ({ query }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        console.log(`블로그 들어옴: ${query}`);
        const response = await fetch(`https://foodzul.com/reviewget?query=${query}`);
        const data = await response.json();
         // HTML 태그 제거
         const cleanedData = data.items.map(item => ({
            ...item,
            title: removeHtmlTagsAndDecode(item.title),
            description: removeHtmlTagsAndDecode(item.description)
          }));

          setBlogs(cleanedData);
      } catch (error) {
        console.error('Naver 오류', error);
      }
    };

    if (query) {
      fetchBlogs();
    }
  }, [query]);

  const handleDivClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <h5 className='tta'>N블로그 사진 리뷰</h5>
      {blogs.map((blog, index) => (
        <div className="price-area2" key={index} onClick={() => handleDivClick(blog.link)}
        style={{ cursor: "pointer" }}>
          <a className="titlereview2" href={blog.link} target="_blank" rel="noopener noreferrer">{blog.title}</a>
          <p className="disc2">{blog.description}</p>
        </div>
      ))}
    </div>
  );
};

export default NaverBlog;
